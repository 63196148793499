import urlDataProyectos from './dataProyectos.json';
import urlDataArtistas from './dataArtistas.json';

let dataProyectos = [];
let dataArtistas = [];
let paises = [];
let paises2Show = [];

const LIMIT_PROYECTOS = 6;
let queryProyectos = '';
let offsetProyectos = 0;

const LIMIT_ARTISTAS = 10;
let queryArtistas = '';
let offsetArtistas = 0;

const location = window.location.href;
let lang = 'es';
if (location.indexOf('en.html') > 0) {
    lang = 'en';
} else if (location.indexOf('index.html') > 0) {
    lang = 'es';
}


const getPaises = () => {
    return Array.from(
        new Set(
            dataArtistas.map(
                artista => artista.pais
            )
        )
    ).sort();
};

const fetchJson = async (url) => {
    try {
        const data = await fetch(url);
        const response = await data.json();
        return response;
    } catch (err) {
        console.error(err);
    }
};



const searchProyectos = async (query='') => {
    if (query !== queryProyectos) {
        offsetProyectos = 0;
        queryProyectos = query;
    }
    try {
        if (dataProyectos.length === 0) {
            const dataProyectosObj = await fetchJson(urlDataProyectos);
            if (dataProyectosObj) {
                dataProyectos = dataProyectosObj.proyectos;
            }
        }
        let data = (query) ? dataProyectos.filter(el => el.tipo_evento.toLowerCase().replace(/ +/g, '').indexOf(query.toLowerCase()) >= 0) : dataProyectos;
        const verMasProyectosEl = document.getElementById('ver_mas_proyectos');
        if (verMasProyectosEl) {
            if (data.length <= offsetProyectos + LIMIT_PROYECTOS) {
                if (!verMasProyectosEl.classList.contains('none')) {
                    verMasProyectosEl.classList.add('none');
                }
            } else {
                verMasProyectosEl.classList.remove('none');
            }
        }
        data = data.slice(0, offsetProyectos + LIMIT_PROYECTOS);
        offsetProyectos += LIMIT_PROYECTOS;
        return data;
        /*
        return data.sort((a, b) => {
            return a.nombre.localeCompare(b.nombre, undefined, {numeric: true, sensitivity: 'base', ignorePunctuation: true});
        });
        */
    } catch (err) {
        console.error(err);
    }
};


const searchArtistas = async (query='') => {
    if (query !== queryArtistas) {
        offsetArtistas = 0;
        queryArtistas = query;
    }
    try {
        if (dataArtistas.length === 0) {
            const dataArtistasObj = await fetchJson(urlDataArtistas);
            if (dataArtistasObj) {
                dataArtistas = dataArtistasObj.artistas;
            }
            paises = getPaises();
        }
        let data = (query) ? dataArtistas.filter(el => el.pais.toLowerCase().replace(/ +/g, '').indexOf(query.toLowerCase()) >= 0) : dataArtistas;
        const verMasArtistasEl = document.getElementById('ver_mas_artistas');
        if (verMasArtistasEl) {
            if (data.length <= offsetArtistas + LIMIT_ARTISTAS) {
                if (!verMasArtistasEl.classList.contains('none')) {
                    verMasArtistasEl.classList.add('none');
                }
            } else {
                verMasArtistasEl.classList.remove('none');
            }
        }
        data = data.slice(0, offsetArtistas + LIMIT_ARTISTAS);
        offsetArtistas += LIMIT_ARTISTAS;
        return data;
        /*
        return data.sort((a, b) => {
            return a.nombre.localeCompare(b.nombre, undefined, {numeric: true, sensitivity: 'base', ignorePunctuation: true});
        });
        */
    } catch (err) {
        console.error(err);
    }
};



window.addEventListener('load', (event) => {
    const linksProyectos = document.querySelectorAll('#nav_proyectos a');
    if (linksProyectos && linksProyectos.length) {
        for (const link of linksProyectos) {
            link.addEventListener('click', (event) => {
                for (const link2 of linksProyectos) {
                    link2.classList.remove('active');
                }
                link.classList.add('active');
                searchProyectos(link.dataset.proyecto)
                .then((res) => {
                    renderProyectos(res);
                }).catch(err => {
                    console.error(err);
                });
            });
        }

        document.getElementById('ver_mas_proyectos').addEventListener('click', (e) => {
            searchProyectos(queryProyectos)
            .then((res) => {
                renderProyectos(res);
            }).catch(err => {
                console.error(err);
            });
        });

        document.getElementById('ver_mas_artistas').addEventListener('click', (e) => {
            searchArtistas(queryArtistas)
            .then((res) => {
                renderArtistas(res);
            }).catch(err => {
                console.error(err);
            });
        });

        document.getElementById('todos_paises').addEventListener('focus', (e) => {
            paises2Show = [...paises];
            renderPaises();
        });

        document.getElementById('todos_paises').addEventListener('keyup', (e) => {
            if (paises.length) {
                paises2Show = paises.filter(el => el.toLowerCase().indexOf(e.target.value.toLowerCase()) === 0);
                renderPaises();
            }
        });

        /*
        document.getElementById('todos_paises').addEventListener('blur', (e) => {
            e.target.value = '';
            renderPaises(false);
        });
        */

        document.addEventListener('click', (e) => {
            const el = e.target;
            const divPaises = document.getElementById('div_paises');
            const children = divPaises.querySelectorAll("*");
            if (el === divPaises) {
                return;
            }
            for (const child of children) {
                if (child === el) {
                    return;
                }
            }

            document.getElementById('todos_paises').value = '';
            renderPaises(false);
        });
    }
    
    fetchJson(urlDataProyectos)
    .then((res) => {
        const dataProyectosObj = res.find(el => el.lang === lang);
        if (dataProyectosObj) {
            dataProyectos = dataProyectosObj.proyectos;
        }
        return searchProyectos('');
    }).then((res) => {
        renderProyectos(res);
    }).catch(err => {
        console.error(err);
    });

    fetchJson(urlDataArtistas)
    .then((res) => {
        const dataArtistasObj = res.find(el => el.lang === lang);
        if (dataArtistasObj) {
            dataArtistas = dataArtistasObj.artistas;
        }
        paises = getPaises();
        return searchArtistas();
    }).then((res) => {
        renderArtistas(res);
    }).catch(err => {
        console.error(err);
    });
});


const renderPaises = (show=true) => {
    const paisesEl = document.getElementById('paises');
    if (paisesEl) {
        if (!show) {
            if (paisesEl.classList.contains('active')) {
                paisesEl.classList.remove('active');
            }
            return;
        }
        if (!paisesEl.classList.contains('active')) {
            paisesEl.classList.add('active');
        }
        const lis = paisesEl.querySelectorAll('ul li');
        if (lis.length > 0 && lis.length === paises2Show.length) {
            return;
        }
        while (paisesEl.lastElementChild) {
            paisesEl.removeChild(paisesEl.lastElementChild);
        }
        const ul = document.createElement('ul');
        for (const pais of paises2Show) {
            const li = document.createElement('li');
            li.classList.add('color-blanco');
            li.innerHTML = pais;
            ul.appendChild(li);
        }
        paisesEl.appendChild(ul);
        Array.from(paisesEl.querySelectorAll('ul li')).forEach(li => {
            li.addEventListener('click', (event) => {
                event.preventDefault();
                event.stopPropagation();
                document.getElementById('todos_paises').value = '';
                searchArtistas(event.target.innerHTML.toString().trim())
                .then((res) => {
                    renderPaises(false);
                    renderArtistas(res);
                }).catch(err => {
                    console.error(err);
                });
            });
        });
    }
};

const renderArtistas = (res) => {
    const div_resultados_artistas = document.querySelector('#div_resultados_artistas');
    if (div_resultados_artistas) {
        while (div_resultados_artistas.lastElementChild) {
            div_resultados_artistas.removeChild(div_resultados_artistas.lastElementChild);
        }
        for (const data of res) {
            const div = document.createElement('div');
            div.classList.add('elemento__tarjeta');
            div.innerHTML = `
                <div>
                    <a href="${data.url}"><img src="${data.imagen || 'assets/dist/img/004.png'}" alt="" target="_blank" /></a>
                </div>
                <p class="color-gris align-center uppercase">
                    ${data.pais}
                </p>
                <h3 class="elemento__titulo--small align-center">
                    <a href="${data.url}" target="_blank" >${data.nombre}</a>
                </h3>
            `;
            div_resultados_artistas.appendChild(div);
        }
    }
};


const renderProyectos = (res) => {
    const div_resultados_proyectos = document.querySelector('#div_resultados_proyectos');
    if (div_resultados_proyectos) {
        while (div_resultados_proyectos.lastElementChild) {
            div_resultados_proyectos.removeChild(div_resultados_proyectos.lastElementChild);
        }
        for (const data of res) {
            const div = document.createElement('div');
            div.classList.add('elemento__tarjeta');
            div.innerHTML = `
                <div>
                    <a href="${data.enlace}"><img src="${data.img || 'assets/dist/img/004.png'}" alt="" target="_blank" />
                    <div class="elemento__tarjeta--contenido">
                        <p>
                            ${data.resumen}
                        </p>
                    </div>
                    </a>
                </div>
                <p class="color-gris align-center uppercase">
                    ${data.ciudad} | ${data.lugar}
                </p>
                <h3 class="elemento__titulo--small align-center">
                    <a href="${data.enlace}" target="_blank" >${data.nombre}</a>
                </h3>
            `;
            div_resultados_proyectos.appendChild(div);
        }
    }
};